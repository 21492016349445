import wx from 'weixin-js-sdk'
import { getWxSDKInitData } from '@/api/common/index'
// import { Toast } from 'vant'

export const wxAppid = process.env.VUE_APP_WX_APPID

export interface IMessageConfig {
  title: string
  desc: string
  link: string
  imgUrl: string
}

export interface ITimelineConfig {
  title: string
  link: string
  imgUrl: string
}

export const wxShareInit = (mes_config: IMessageConfig, time_config: ITimelineConfig): Promise<any> => {
  return new Promise((resolve, reject) => {
    getWxSDKInitData({
      url: encodeURIComponent(location.href.split('#')[0]),
    }).then((res: any) => {
      wx.config({
        debug: false,
        appId: wxAppid,
        timestamp: res.timestamp,
        nonceStr: res.noncestr,
        signature: res.signature,
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'chooseWXPay'],
      })
      wx.ready(function () {
        resolve('')
        // 朋友 及 QQ
        wx.updateAppMessageShareData({
          title: mes_config.title,
          desc: mes_config.desc,
          link: mes_config.link,
          imgUrl: mes_config.imgUrl,
          success: function () {
            // Toast('好友分享设置成功')
          },
        }),
          // 朋友圈 及 QQ空间
          wx.updateTimelineShareData({
            title: time_config.title,
            link: time_config.link,
            imgUrl: time_config.imgUrl,
            success: function () {
              // Toast('朋友圈分享设置成功')
            },
          })
      })
      wx.error(function (res) {
        console.log(res)
        reject()
      })
    })
  })
}
